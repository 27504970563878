//
//  Responsive variables
//  _____________________________________________

$use-flex    : true !default; // Use flexbox [true|false]
$responsive  : true !default; // Theme is responsive [true|false]

$screen__xxs : 320px !default;
$screen__xs  : 480px !default;
$screen__s   : 576px !default;
$screen__m   : 768px !default;
$screen__l   : 992px !default;
$screen__xl  : 1200px !default;

@include max-screen($screen__xxs) {
    .no-display {
        display: none;
    }
}

@include min-screen($screen__xxs) {
    .no-display {
        display: none;
    }
}

@include max-screen($screen__xs) {
    .no-display {
        display: none;
    }
}
@include min-screen($screen__xs) {
    .no-display {
        display: none;
    }
}
@include max-screen($screen__s) {
    .no-display {
        display: none;
    }
}
@include min-screen($screen__s) {
    .no-display {
        display: none;
    }
}
@include max-screen($screen__m) {
    .no-display {
        display: none;
    }
}
@include min-screen($screen__m) {
    .no-display {
        display: none;
    }
}

@include max-screen($screen__l) {
    .no-display {
        display: none;
    }
}

@include min-screen($screen__l) {
    .no-display {
        display: none;
    }
}

@include max-screen($screen__xl) {
    .no-display {
        display: none;
    }
}

@include min-screen($screen__xl) {
    .no-display {
        display: none;
    }
}