//  Newsletter subscription
.block.newsletter {
    margin-bottom: $indent__xl;

    .form.subscribe {
        display: table;
        width: 100%;
    }

    .fieldset {
        display: table-cell;
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;

        .control {
            @include lib-icon-font(
                $icon-envelope,
                $_icon-font-size        : 35px,
                $_icon-font-line-height : 33px,
                $_icon-font-color       : $form-element-input-placeholder__color
            );
            display: block;

            &:before {
                position: absolute;
            }
        }
    }

    input {
        padding-left: 35px;
    }

    .title {
        display: none;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 1%;
    }

    .action.subscribe {
        @include lib-button(
                $_button-color : $custom_color_2,
                $_button-color-hover : $color-white,
                $_button-background : $color-white,
                $_button-background-hover : transparent,
                $_button-border : 1px solid $color-white,
                $_button-border-hover : 1px solid $color-white

        );
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.newsletter {
        width: 32%;

        .field {
            margin-right: 5px;

            .control {
                width: 100%;
            }
        }
    }
}
