//
//  Color variables
//  _____________________________________________

$color-white             : #fff !default;
$color-black             : #000 !default;

$color-gray19            : #303030 !default;
$color-gray20            : #333 !default;
$color-gray34            : #575757 !default;
$color-gray37            : #5e5e5e !default;
$color-gray40            : #666 !default;
$color-gray43            : #6d6d6d !default;
$color-gray46            : #757575 !default;
$color-gray52            : #858585 !default;
$color-gray55            : #8c8c8c !default;
$color-gray56            : #8f8f8f !default;
$color-gray60            : #999 !default;
$color-gray62            : #9e9e9e !default;
$color-gray64            : #a3a3a3 !default;
$color-gray68            : #adadad !default;
$color-gray76            : #c2c2c2 !default;
$color-gray78            : #c7c7c7 !default;
$color-gray79            : #c9c9c9 !default;
$color-gray80            : #ccc !default;
$color-gray82            : #d1d1d1 !default;
$color-gray89            : #e3e3e3 !default;
$color-gray90            : #e5e5e5 !default;
$color-gray91            : #e8e8e8 !default;
$color-gray92            : #ebebeb !default;
$color-gray94            : #f0f0f0 !default;
$color-gray95            : #f2f2f2 !default;
$color-white-smoke       : #f5f5f5 !default;
$color-white-dark-smoke  : #efefef !default;
$color-white-fog         : #f8f8f8 !default;

$color-gray-light0       : #f6f6f6 !default;
$color-gray-light01      : #f4f4f4 !default;
$color-gray-light1       : #e5efe5 !default;
$color-gray-light2       : #bbb !default;
$color-gray-light3       : #aeaeae !default;
$color-gray-light4       : #cecece !default;
$color-gray-light5       : #c1c1c1 !default;
$color-gray-light6       : #c5c5c5 !default;

$color-gray-middle1      : #e4e4e4 !default;
$color-gray-middle2      : #c6c6c6 !default;
$color-gray-middle3      : #7e807e !default;
$color-gray-middle4      : #6e716e !default;
$color-gray-middle5      : #707070 !default;

$color-gray-darken0      : #eee !default;
$color-gray-darken1      : #e2e2e2 !default;
$color-gray-darken2      : #cdcdcd !default;
$color-gray-darken3      : #555 !default;
$color-gray-darken4      : #494949 !default;

$color-red9              : #ff0101 !default;
$color-red10             : #e02b27 !default;
$color-red11             : #b30000 !default;
$color-red12             : #d10029 !default;

$color-orange-red1       : #ff5501 !default;
$color-orange-red2       : #ff5601 !default;
$color-orange-red3       : #ff5700 !default;
$color-orange-red4       : #fc5e10 !default;

$color-dark-green1       : #006400 !default;

$color-blue1             : #1979c3 !default;
$color-blue2             : #006bb4 !default;
$color-blue3             : #00699D !default;
$color-sky-blue1         : #68a8e0 !default;

$color-pink1             : #fae5e5 !default;
$color-dark-pink1        : #800080 !default;

$color-brownie1          : #6f4400 !default;
$color-brownie-light1    : #c07600 !default;

$color-yellow-light1     : #fdf0d5 !default;
$color-yellow-light2     : #ffee9c !default;
$color-yellow-light3     : #d6ca8e !default;
$color-yellow1           : #ff0 !default;

//
//  Color nesting
//  ---------------------------------------------

$custom_color_1: #f6f5f1;
$custom_color_2: #b21c36;
$custom_color_3: #C10135;
$custom_color_4: #529521;


$primary__color          : $color-black !default;
$primary__color__dark    : darken($primary__color, 35%) !default;
$primary__color__darker  : darken($primary__color, 13.5%) !default;
$primary__color__lighter : lighten($primary__color, 29%) !default;
$primary__color__light   : lighten($primary__color, 45%) !default;

$secondary__color        : $color-gray91 !default;
$secondary__color__light : lighten($secondary__color, 5%) !default;

$page__background-color  : $color-white !default;
$panel__background-color : $color-gray-light0 !default;

$active__color           : $custom_color_2 !default;
$error__color            : $color-red10 !default;
