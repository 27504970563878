// Happy Styling
textarea,
input {
    outline: 0 !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
        outline: 0 !important;
        box-shadow: none !important;
    }
}

button {
    box-shadow: none !important;
}

.table {
    display: table;
}

i {
    font-style: normal;
}

header .block.block-search {
    margin-bottom: 0;
}

.toolbar-products {
    background-color: $custom_color_1;
    border: 1px solid #e8e8e8;
    padding: 10px;
    position: relative;
}

.products-grid {
    .product-item {
        .price-details {
            font-size: 80%;
        }
        .price-box {
            margin-bottom: 0;
        }
        .rating-summary {
            cursor: pointer;
        }
    }
    .product-item-inner {
        padding-top: 7px;
    }

    .minimal-price-link {
        display: inline;
    }
}

.toolbar select {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
}

.fotorama__caption {
    display: none;
}
.fotorama__nav.fotorama__nav--thumbs {
    padding-top: 15px;
}

.header.panel > .header.links > .authorization-link a i {
    @include lib-icon-font(
            $_icon-font-content     : $icon-account,
            $_icon-font-size        : 28px,
            $_icon-font-line-height : 1.2,
            $_icon-font-display     : inline-block,
            $_icon-font-color       : $color-white,
            $_icon-font-color-hover : darken($color-white, 15%),
            $_icon-font-position    : before
    );

    &:hover {
        color: darken($color-white, 15%);
    }
}

body .page-footer {
    background-color: $custom_color_2;
    border-top: 1px solid $custom_color_2;
    padding-bottom: 0;

    .footer-container {
        max-width: 1280px;
        padding: 30px 20px 0;
        margin: 0 auto;
    }

    .footerTop {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap : nowrap;
        flex-wrap : nowrap;
        -webkit-box-pack : justify;
        -ms-flex-pack : justify;
        justify-content : space-between;
        -webkit-box-align : start;
        -ms-flex-align : start;
        align-items : flex-start;

        .footerAdditional {
            width: 30%;
            .block.newsletter {
                width: 100%;
                .title {
                    display: block;
                }
            }
        }

    }

    .footerLinks {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap : nowrap;
        flex-wrap : nowrap;
        -webkit-box-pack : justify;
        -ms-flex-pack : justify;
        justify-content : space-between;
        -webkit-box-align : start;
        -ms-flex-align : start;
        align-items : flex-start;
        width: 100%;
        max-width: 65%;
        margin: 0 -10px;

        .item {
            margin: 0 10px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    address {
        color: #fff;
        box-sizing: border-box;
        padding: 10px;
        text-align: center;
        display: block;
        margin-top: 25px;
    }

    a {
        color: $color-white;

        &:hover {
            color: darken($color-white, 15%);
        }
    }

    label,
    strong {
        color: $color-white;
    }
}

.product-info-main {
    .product-info-price {
        border-color: $border-color__base;
        display: block;
        margin-top: 15px;

        .price-box {
            display: block;
            width: 100%;
            padding-bottom: 0;

            .price-container .price {
                font-size: 140%;
                line-height: 1;
            }
        }

        .tax-details {
            font-size: 80%;
        }
    }

    .product.attribute.sku {
        .type {
            font-weight: 700;

            &:after {
                content: '';
            }
        }
    }

    .product.attribute.delivery_time .value {
        display: inline-block;
    }

    .page-title-wrapper.product h1 {
        margin-bottom: 0;
    }

    .prices-tier {
        padding: 10px;
        background-color: $custom_color_1;
        border: 1px solid $border-color__base;

        .benefit {
            color: $custom_color_4;
        }
    }

    .box-tocart .fieldset {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap : nowrap;
        flex-wrap : nowrap;
        -webkit-box-pack : start;
        -ms-flex-pack : start;
        justify-content : flex-start;
        -webkit-box-align : stretch;
        -ms-flex-align : stretch;
        align-items : stretch;
        margin-bottom: 0;

        .action.tocart {
            width: 210px;
            height: 52px;
            font-size: 14px;
        }

        .qty {
            margin-bottom: 0;
        }

        input {
            height: 52px;
        }
    }

    .action.mailto.friend {
        display: block;
    }

    .product-reviews-summary {
        float: none;
    }

    .prices-tier {
        .price:after {
            content: '*';
        }

        + .price-details {
            color: #575757;
            font-size: 80%;
            .tax-details:before {
                content: '*';
            }
        }
    }
}

.block-minicart {
    .items-total {
        float: none;
        margin: 0;
    }

    .minicart-items .product-item-name {
        margin: 0;
    }

    .subtotal {
        text-align: left;
        clear: both;
        margin: 0 0 10px;

        .label {
            vertical-align: middle;
        }

        .amount {
            float: right;
            vertical-align: middle;
        }
    }

    .block-content > .actions {
        margin-top: 20px;

        > .primary {
            margin: 0 0 15px;
        }
    }

    .product-item {
        margin-bottom: 0 !important;
    }
}

.minicart-wrapper .minicart-widgets {
    margin: 0;
}


.cart.table-wrapper .item  {
    .col.item {
        padding-bottom: 0;
    }
}

.checkout-container .button {
    &:focus,
    &:active {
        color: $custom_color_3;
    }
}

.account .sidebar-additional {
    margin-top: 0;
}
.abs-block-widget-title, .block-reorder .block-title, .widget .block-title, .block-wishlist .block-title, .block-compare .block-title {
    margin-bottom: 10px;
}

.block.newsletter .form.subscribe {
    display: block;

    .dataProtectionContainer {
        margin-top: 10px;

        label {
            font-size: 80%;
        }
    }
}

.navigation .submenu:not(:first-child) .active > a,
.navigation .level0.active .all-category .ui-state-focus,
.navigation .level0.active > a:not(.ui-state-active), .navigation .level0.has-active > a:not(.ui-state-active) {
    border-color: $custom_color_3;
    color: $primary__color;
}

@media (max-width: 930px) {
    body .page-footer {
        .footerLinks {
            max-width: 60%;
            flex-wrap: wrap;
            .item {
                width: 45%;

                &:first-child,
                &:nth-child(2) {
                    margin-bottom: 20px;
                }
            }
        }
        .footerTop .footerAdditional {
            width: 35%;
        }
    }
}

@media (max-width: 768px) {
    .product-social-links {
        text-align: left;
    }
    .product.info.detailed {
        overflow: hidden;
        margin-bottom: 0;
        .product.data.items {
            margin: 0 0 20px;
        }
    }

    .product.data.items {
        border-bottom: 0;
        margin-bottom: 0;
        > .item.title {
            margin-top: 15px;
            &:first-child {
                margin-top: 0;
            }
            > .switch {
                border: 1px solid $border-color__base;
                background-color: $custom_color_1;
            }
        }

        .item.content {
            border: 1px solid $border-color__base;
            border-top: 0;
        }
    }

    body .page-footer {
        .footer-container {
            padding-left: 15px;
            padding-right: 15px;
        }
        .footerLinks {
            max-width: 100%;
            margin: 0;
        }
        .footerTop {
            --webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            .footerAdditional {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 480px) {
    body .page-footer {
        .footer-container {
            padding-left: 0;
            padding-right: 0;
        }
        .footerLinks {
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            .item {
                width: 100%;
                margin: 0 20px 20px !important;

                ul {
                    border-top: 1px solid $border-color__base;

                    li {
                        border-bottom: 1px solid $border-color__base;
                        padding: 0 15px;
                        margin-bottom: 0;
                        &:last-child {
                            border: 0;
                        }

                        a {
                            display: block;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }

        .footerTop .footerAdditional {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 5px;
            width: calc(100% - 30px);
        }
    }
}

.specialDeliveryHint {
    max-width: 1220px;
    width: calc(100% - 44px);
    margin: 20px auto;
    padding: 20px;
    background: #f8e5e7;
    color: #e3413f;
    border: 2px solid #e3413f;
}

.catalog-product-view .specialDeliveryHint {
    width: calc(100% - 64px);
}

.mediaroxCategoryTeaser {
    text-align: center;
    margin: 30px 0;
    @include min-screen($screen__l) {
        padding: 0 2%;
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;

        .item {
            padding: 20px;
            width: 90%;

            @include min-screen($screen__xxs) {
                width: 45%;
            }

            @include min-screen($screen__m) {
                width: 27%;
            }
        }
    }

    .catIcon {
        display: block;
        margin: 0 auto 20px;
        max-width: 175px;
    }

    .catLink {
        text-transform: uppercase;
        font-size: 20px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
}

.homeContent {
    @include min-screen($screen__m) {
        margin: 60px 0 !important;

        .blocks .flex {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;

            .item {
                flex: 0 0 48%;
            }
        }
    }
}

.homeSlider {
    text-align: center;
    img,
    picture {
        margin: 0 auto;
    }

    .sliderText {
        position: absolute;
        left: 10px;
        top: 20px;
        right: 10px;
        bottom: 10px;
        color: #fff;
        text-align: center;
    }

    .sliderHeadLine {
        font-weight: bold;
        font-size: 300%;
    }

    .sliderInfo {
        font-size: 150%;
        display: none;
    }

    @include min-screen($screen__m) {
        .sliderText {
            left: 20px;
            top: auto;
            right: 20px;
            bottom: 20px;
            text-align: left;
        }

        .sliderInfo {
            display: block;
        }
    }
}

.desktopAnchor {
    display: none;
    @include min-screen($screen__m) {
        display: block;
    }
}

.contentToggle,
.mobileAnchor {
    display: block;
    @include min-screen($screen__m) {
        display: none;
    }
}

.themeFilterArea {
    margin: 20px 0;

    @include min-screen($screen__m) {
        margin: 0;
    }
}
